import React, { useEffect, useState, useMemo } from "react";
import { Button, Select, Modal, Breadcrumb, Upload, AuthProvider, Icon, AuthMachine } from "common-components";
import { Radio as AntRadio, Space as AntSpace, Tabs } from "antd";
import { useMachine } from "@xstate/react";
import { getBookMetaSelection, getBookMetaBooks } from "api/bookMeta";
import {
	getChapterBooksRelated,
	importLevel,
	importKnowledge,
	importExamChapterKnowledge,
	importExamChapterLevel,
	importSyllabusKnowledge,
	importSyllabusLevel,
} from "api/chapter";
import openNotificationWithIcon from "utils/hooks/useNotification";
import { v4 as uuidv4 } from "uuid";
import { fetchMachine, FetchEventType, FetchStates } from "machines/fetchMachine";
import { useHistory } from "react-router-dom";
import { setSelectOptions, getTableAuthority, calStudentYear } from "utils/common";
import { createValidator, required } from "utils/validation";
import { useFirebaseStorage } from "utils/hooks/useFirebaseStorage";
import useQuery from "utils/hooks/useQuery";
import { INIT_AUTHORITY } from "constants/index";
import { UiTop, UiActionBox, UiTableTop } from "styles";
import { CurriculumTag } from "components";
import { UiChapterPage } from "./ChapterPage.style";
import { getBasicTableData } from "api/definition";

import { ELEMENTARY_LIST, SECONDARY_LIST, HIGH_LIST, SPECIAL_LIST } from "./eduSubjectList";
import { importKnowledgeProposition, importLevelProposition } from "api/chapter/index";
/**
 * 章節資料-課本章節
 */
const { useAuthState } = AuthProvider;

const SPECIAL_SUBJECT = "特殊科目(健體、綜合活動、科技)";
const NORMAL_SUBJECT = "一般科目";
const EXAM_CHAPTER = "大考冊次";
const NO_SYLLABUS = "主題式無課綱";

const TABS = {
	knowledge: "knowledge",
	chapter: "chapter",
	book: "book",
	topic: "topic",
};

export const ChapterPage = () => {
	const query = useQuery();
	const queryYear = query.get("year");
	const queryEducation = query.get("education");
	const querySubject = query.get("subject");
	const authState = useAuthState();
	const history = useHistory();
	const [state, setState] = useState({
		yearValue: "",
		educationalValue: "",
		subjectValue: "",
	});
	const { upload } = useFirebaseStorage();
	const [uploadPopup, setUploadPopup] = useState({
		visible: false,
		loading: false,
	});

	const { state: AuthStates } = AuthMachine;

	const [stateFormParams, setFormParams] = useState({});

	const [authorityState, setAuthority] = useState(INIT_AUTHORITY);
	const [levelRadio, setLevelRadio] = useState(NORMAL_SUBJECT);

	const [activeKey, setActiveKey] = useState(TABS.knowledge);

	const [isSubmitted, setIsSubmitted] = useState(false);
	const [versionMap, setVersionMap] = useState([]);

	const isKnowledgeFileEmpty = useMemo(() => !stateFormParams?.fileList?.value?.length, [
		stateFormParams?.fileList?.value,
	]);
	const isPropositionFileEmpty = useMemo(() => !stateFormParams?.fileListProposition?.value?.length, [
		stateFormParams?.fileListProposition?.value,
	]);
	const isBigTestFileEmpty = useMemo(() => !stateFormParams?.bigTest?.value?.length, [stateFormParams?.bigTest?.value]);

	const [stateBookMetaBooks, sendBookMetaBooks] = useMachine(fetchMachine, {
		services: {
			fetchData: async (_ctx, event) => {
				const { isSuccess, message, systemCode, data } = await getBookMetaBooks(event.year, event.payload);
				const bookList = data;
				return {
					isSuccess,
					message,
					systemCode,
					bookList: bookList
						? Object.entries(bookList).map(([key, value]) => {
								return {
									title: key,
									data: value,
								};
						  })
						: [],
				};
			},
		},
	});

	const [stateBookMetaSelection, sendBookMetaSelection] = useMachine(fetchMachine, {
		services: {
			fetchData: async (_ctx, event) => {
				const { isSuccess, message, data } = await getBookMetaSelection();
				const { yearMap, eduMap, eduSubject } = data;
				return {
					isSuccess,
					message,
					yearMap: setSelectOptions(yearMap),
					eduSubject,
					eduMap: setSelectOptions(eduMap),
				};
			},
		},
	});

	const [stateBooksRelated, sendBooksRelated] = useMachine(fetchMachine, {
		services: {
			fetchData: async (_ctx, event) => {
				const { data } = await getChapterBooksRelated(event.year, event.payload);
				return {
					bookMap: setSelectOptions(data?.bookMap),
					curriculumMap: setSelectOptions(data?.curriculumMap),
				};
			},
		},
	});

	const { bookList } = stateBookMetaBooks.context.result || {};
	const { yearMap, eduMap, eduSubject } = stateBookMetaSelection.context.result || {};
	const { bookMap, curriculumMap } = stateBooksRelated.context.result || {};

	const sortBookMap = bookMap?.sort((a, b) => {
		return a.value.localeCompare(b.value);
	});

	// 取得全部版本
	const [_, sendSelection] = useMachine(fetchMachine, {
		services: {
			fetchData: async (_context, event) => {
				const { data, isSuccess, message } = await getBasicTableData();
				const versionMap = data.data["PUBLISHER"].map((item) => ({
					name: item.name,
					value: item.code,
				}));

				return {
					isSuccess,
					message,
					versionMap: setVersionMap(versionMap),
				};
			},
		},
	});

	useEffect(() => {
		if (!authState.matches(AuthStates.LoginDone)) return;

		sendSelection(FetchEventType.Fetch);
	}, [authState.value]);

	const onSelectChangeHandle = (value, key) => {
		if (key === "educationalValue") {
			setState({
				...state,
				educationalValue: value,
				subjectValue: "",
			});
		} else {
			setState({
				...state,
				[key]: value,
			});
		}
	};

	const onSearchHandle = () => {
		if (!state.yearValue || !state.educationalValue || !state.subjectValue) return;
		sendBookMetaBooks(FetchEventType.Fetch, {
			year: state.yearValue,
			payload: {
				edu: state.educationalValue,
				subject: state.subjectValue,
			},
		});
	};

	const goChapterList = (name, code) => {
		history.push(`/chapter/list?code=${code}`);
	};

	const toggleUploadPopupVisible = () => {
		setUploadPopup({
			...uploadPopup,
			visible: !uploadPopup.visible,
		});
	};

	const onFormSelectChangeHandle = (value, key) => {
		setIsSubmitted(false);
		setFormParams({
			...stateFormParams,
			[key]: {
				value,
				message: "",
			},
		});
	};

	const submitFiles = async () => {
		setIsSubmitted(true);
		let rules = {
			subject: [{ message: "學制科目欄位不得空白", validate: required }],
			year: [{ message: "適用年度欄位不得空白", validate: required }],
			curriculum: [{ message: "課綱欄位不得空白", validate: required }],
			version: [{ message: "版本欄位不得空白", validate: required }],
		};
		if (levelRadio === SPECIAL_SUBJECT) {
			delete rules.version;
		}
		if (levelRadio === EXAM_CHAPTER) {
			rules = {
				...rules,
				book: [{ message: "冊次欄位不得空白", validate: required }],
			};
		}
		if (levelRadio === NO_SYLLABUS) {
			delete rules.curriculum;
		}

		const inputValues = {
			subject: "",
			version: "",
			year: "",
			curriculum: "",
			book: "",
		};
		Object.entries(stateFormParams).forEach(([key, value]) => {
			inputValues[key] = value.value;
		});

		const [isPass, errorGroup] = createValidator(inputValues, rules);
		setFormParams({ ...stateFormParams, ...errorGroup });
		if (isPass) return;

		switch (activeKey) {
			case TABS.knowledge:
				if (!stateFormParams?.fileList?.value?.length) return;
				if (!stateFormParams?.fileListProposition?.value?.length) return;
				break;
			case TABS.chapter:
			case TABS.topic:
				if (!stateFormParams?.fileList?.value?.length) return;
				break;
			case TABS.book:
				if (!stateFormParams?.fileList?.value?.length) return;
				if (!stateFormParams?.bigTest?.value?.length) return;
				break;
			default:
				break;
		}

		let result = {};
		let levelResult = {};
		setUploadPopup({
			...uploadPopup,
			loading: true,
		});

		const eduSubject = inputValues.subject;
		const year = inputValues.year;
		const version = inputValues.version;
		const book = inputValues.book;

		const specialSubjectParam = `${eduSubject}/${year}`;
		const normalSubjectParam = `${eduSubject}/${version}/${year}`;
		const noSyllabusParam = `${version}/${year}`;

		const code = levelRadio === SPECIAL_SUBJECT ? specialSubjectParam : normalSubjectParam;
		const bookID = `${year}${version}-${eduSubject}${book}`;

		await Promise.all(
			stateFormParams.fileList.value.map(async (file) => {
				const uid = uuidv4();
				const res = await upload(`chapter/${uid}-${file.name}`, file);

				const payload = {
					curriculum: inputValues.curriculum,
					url: res.url,
					fileName: file.name,
				};

				// 一階層 多階層
				if (activeKey === TABS.knowledge) {
					result = await importKnowledgeProposition(payload);
				}

				if (activeKey === TABS.chapter) {
					result = await importKnowledge(payload);
					if (result.isSuccess) {
						levelResult = await importLevel(code, payload);
					}
				}

				// 大考冊次
				if (activeKey === TABS.book) {
					const knowledgePayload = {
						url: res.url,
						fileName: file.name,
					};
					result = await importExamChapterKnowledge(eduSubject, knowledgePayload);
				}
				// 主題式無課綱
				if (activeKey === TABS.topic) {
					const knowledgePayload = {
						eduSubject: eduSubject,
						url: res.url,
						fileName: file.name,
					};
					result = await importSyllabusKnowledge(knowledgePayload);
					if (result.isSuccess) {
						levelResult = await importSyllabusLevel(noSyllabusParam, knowledgePayload);
					}
				}
			}),
		);

		if (activeKey === TABS.book) {
			await Promise.all(
				stateFormParams.bigTest.value.map(async (file) => {
					const uid = uuidv4();
					const res = await upload(`chapter/${uid}-${file.name}`, file);

					const payload = {
						curriculum: inputValues.curriculum,
						url: res.url,
						fileName: file.name,
					};

					// 大考章節
					if (levelRadio === EXAM_CHAPTER) {
						levelResult = await importExamChapterLevel(bookID, payload);
					}
				}),
			);
		}
		if (activeKey === TABS.knowledge) {
			await Promise.all(
				stateFormParams.fileListProposition.value.map(async (file) => {
					const uid = uuidv4();
					const res = await upload(`chapter/${uid}-${file.name}`, file);

					const payload = {
						curriculum: inputValues.curriculum,
						url: res.url,
						fileName: file.name,
					};

					// 知識概念
					levelResult = await importLevelProposition(code, payload);
				}),
			);
		}

		if (result?.isSuccess) {
			openNotificationWithIcon("success", "上傳成功！");
		} else {
			openNotificationWithIcon("error", `上傳失敗！${result.message}`);
		}
		if (Object.hasOwn(levelResult, "isSuccess") && !levelResult.isSuccess) {
			openNotificationWithIcon("error", `上傳失敗！${levelResult.message}`);
		}
		setUploadPopup({
			visible: false,
			loading: false,
		});
		setFormParams({});
		onSearchHandle();
	};

	const onFormPopupToggle = () => {
		setUploadPopup({
			...uploadPopup,
			visible: !uploadPopup.isVisible,
		});
	};

	const changeFileList = (value) => {
		if (!value) return;
		setFormParams({
			...stateFormParams,
			fileList: {
				value,
				message: "",
			},
		});
	};

	const changeFileListProposition = (value) => {
		if (!value) return;
		setFormParams({
			...stateFormParams,
			fileListProposition: {
				value,
				message: "",
			},
		});
	};

	const eduSubjectOptions = useMemo(() => {
		switch (levelRadio) {
			case NORMAL_SUBJECT:
				return [...ELEMENTARY_LIST, ...SECONDARY_LIST, ...HIGH_LIST];

			case SPECIAL_SUBJECT:
				return [...SPECIAL_LIST];

			case EXAM_CHAPTER:
				return [...SECONDARY_LIST, ...HIGH_LIST];

			case NO_SYLLABUS:
				return [...ELEMENTARY_LIST, ...SECONDARY_LIST, ...HIGH_LIST];

			default:
				return [...ELEMENTARY_LIST, ...SECONDARY_LIST, ...HIGH_LIST];
		}
	}, [levelRadio]);

	const handleTabChange = (key) => {
		setActiveKey(key);
		switch (key) {
			case TABS.book:
				setLevelRadio(EXAM_CHAPTER);
				break;
			case TABS.topic:
				setLevelRadio(NO_SYLLABUS);
				break;
			default:
				setLevelRadio(NORMAL_SUBJECT);
				break;
		}
	};

	const resetModalContent = () => {
		setFormParams({});
		setIsSubmitted(false);
	};

	useEffect(() => {
		sendBookMetaSelection(FetchEventType.Fetch);
	}, []);

	useEffect(() => {
		if (!yearMap || !eduMap || !stateBookMetaSelection.matches(FetchStates.Resolved)) return;
		const yearValue = queryYear || calStudentYear().toString() || yearMap[0]?.value || "";
		const educationalValue = queryEducation || eduMap[0]?.value || "";
		const subjectValue = querySubject || setSelectOptions(eduSubject[educationalValue])[0].value;

		const { authority, tableAuthority } = getTableAuthority("BOOK", authState.context.authority.function.YearBook);
		setAuthority({
			...authorityState,
			authority,
			tableAuthority,
		});
		setState({
			...state,
			yearValue,
			educationalValue,
			subjectValue,
		});
		sendBookMetaBooks(FetchEventType.Fetch, {
			year: yearValue,
			payload: {
				edu: educationalValue,
				subject: subjectValue,
			},
		});
	}, [stateBookMetaSelection.value]);

	useEffect(() => {
		if (!curriculumMap) return;
		setFormParams({
			...stateFormParams,
			curriculum: {
				value: curriculumMap[curriculumMap.length - 1].value,
				message: "",
			},
		});
	}, [curriculumMap]);

	useEffect(() => {
		if (stateFormParams.year?.value && stateFormParams.subject?.value) {
			sendBooksRelated(FetchEventType.Fetch, {
				year: stateFormParams.year.value,
				payload: {
					edu: stateFormParams.subject.value[0],
					subject: stateFormParams.subject.value[1] + stateFormParams.subject.value[2],
				},
			});
		}
	}, [stateFormParams.subject, stateFormParams.year]);

	useEffect(() => {
		resetModalContent();
	}, [activeKey]);

	useEffect(() => {
		if (uploadPopup.visible) {
			setActiveKey(TABS.knowledge);
			resetModalContent();
		}
	}, [uploadPopup.visible]);

	return (
		<UiChapterPage>
			<UiTableTop>
				<div className="breadcrumb">
					<Breadcrumb
						data={[
							{
								icon: "home",
								name: "首頁-異動紀錄",
								path: "/",
							},
							{
								icon: "list_alt",
								name: `章節資料-課本章節`,
								path: `/chapter`,
							},
						]}
					/>
				</div>
				<UiTop>
					<UiActionBox>
						<Select options={yearMap} value={state.yearValue} onChange={(e) => onSelectChangeHandle(e, "yearValue")} />
						<Select
							options={eduMap}
							value={state.educationalValue}
							onChange={(value) => onSelectChangeHandle(value, "educationalValue")}
						/>
						{state.educationalValue && eduSubject && (
							<Select
								options={setSelectOptions(eduSubject[state.educationalValue])}
								value={state.subjectValue}
								onChange={(value) => onSelectChangeHandle(value, "subjectValue")}
							/>
						)}
						<Button.IconButton variant="blue" full={false} iconName="search" onClick={onSearchHandle}>
							查詢
						</Button.IconButton>
					</UiActionBox>
					<UiActionBox>
						{authorityState.tableAuthority.Edit ? (
							<Button.IconButton variant="blue" full={false} iconName="add" onClick={() => onFormPopupToggle()}>
								新增版本章節
							</Button.IconButton>
						) : (
							""
						)}
					</UiActionBox>
				</UiTop>
			</UiTableTop>
			<div className="chapterPage">
				{bookList &&
					bookList.map((item) => {
						return (
							<div className="chapterBox" key={item.title}>
								<div className="title">{item.title}</div>

								{Object.keys(item.data).length > 0 ? (
									Object.entries(item.data).map(([key, value]) => {
										return (
											<>
												<CurriculumTag year={parseInt(key)} mb={2} />

												<div className="booksBox">
													{value.length > 0 ? (
														value.map((li, index) => {
															return (
																<div key={index} className="bookItem" onClick={() => goChapterList(li.name, li.code)}>
																	{li.isLock ? <Icon mr={2} name="lock" color="#ffb800" type="outLined" /> : ""}
																	{li.name}
																</div>
															);
														})
													) : (
														<div className="noBooks">暫無課本</div>
													)}
												</div>
											</>
										);
									})
								) : (
									<div className="noBooks">暫無冊次</div>
								)}
							</div>
						);
					})}
			</div>
			<Modal
				confirmLoading={uploadPopup.loading}
				visible={uploadPopup.visible}
				onCancel={toggleUploadPopupVisible}
				okText={uploadPopup.loading ? "上傳中..." : "確定"}
				onOk={submitFiles}
				title="上傳檔案"
				width="560px">
				<Tabs activeKey={activeKey} onChange={handleTabChange}>
					<Tabs.TabPane tab="命題概要 (知識概念)" key={TABS.knowledge}>
						<div className="modalContent">
							<div className="selectRadio">
								<div>科目類型</div>
								<AntRadio.Group
									onChange={(e) => {
										setLevelRadio(e.target.value);
										setFormParams({});
									}}
									value={levelRadio}>
									<AntSpace direction="vertical">
										<div>
											<AntRadio value={NORMAL_SUBJECT} style={{ marginRight: "8px" }}>
												{NORMAL_SUBJECT}
											</AntRadio>
											<AntRadio value={SPECIAL_SUBJECT}>{SPECIAL_SUBJECT}</AntRadio>
										</div>
									</AntSpace>
								</AntRadio.Group>
							</div>
							{eduSubject && (
								<Select
									label="學制科目"
									options={eduSubjectOptions}
									value={stateFormParams.subject?.value || ""}
									message={stateFormParams.subject?.message || ""}
									onChange={(value) => onFormSelectChangeHandle(value, "subject")}
								/>
							)}
							<Select
								label="適用年度"
								options={yearMap}
								value={stateFormParams.year?.value || ""}
								message={stateFormParams.year?.message || ""}
								onChange={(value) => onFormSelectChangeHandle(value, "year")}
							/>
							<Select
								label="課綱"
								options={curriculumMap}
								value={stateFormParams.curriculum?.value || ""}
								message={stateFormParams.curriculum?.message || ""}
								onChange={(value) => onFormSelectChangeHandle(value, "curriculum")}
							/>
							{levelRadio !== SPECIAL_SUBJECT && (
								<Select
									label="版本"
									options={versionMap}
									value={stateFormParams.version?.value || ""}
									message={stateFormParams.version?.message || ""}
									onChange={(value) => onFormSelectChangeHandle(value, "version")}
								/>
							)}
							<div>匯入知識向度</div>
							<Upload
								fileList={stateFormParams.fileList?.value || []}
								onChange={changeFileList}
								prompt={
									<>
										僅限上傳一個 Microsoft Excel 檔<br />
										(副檔名 : .xls/.xlsx)
									</>
								}
								acceptFileType=".xls,.xlsx"
								message={isSubmitted && isKnowledgeFileEmpty ? "請上傳檔案" : ""}
							/>
							<div>匯入章節表</div>
							<Upload
								fileList={stateFormParams.fileListProposition?.value || []}
								onChange={changeFileListProposition}
								prompt={
									<>
										僅限上傳一個 Microsoft Excel 檔<br />
										(副檔名 : .xls/.xlsx)
									</>
								}
								acceptFileType=".xls,.xlsx"
								message={isSubmitted && isPropositionFileEmpty ? "請上傳檔案" : ""}
							/>
						</div>
					</Tabs.TabPane>
					<Tabs.TabPane tab="章節重點" key={TABS.chapter}>
						<div className="modalContent">
							<div className="selectRadio">
								<div>科目類型</div>
								<AntRadio.Group
									onChange={(e) => {
										setLevelRadio(e.target.value);
										setFormParams({});
									}}
									value={levelRadio}>
									<AntSpace direction="vertical">
										<div>
											<AntRadio value={NORMAL_SUBJECT} style={{ marginRight: "8px" }}>
												{NORMAL_SUBJECT}
											</AntRadio>
											<AntRadio value={SPECIAL_SUBJECT}>{SPECIAL_SUBJECT}</AntRadio>
										</div>
									</AntSpace>
								</AntRadio.Group>
							</div>
							{eduSubject && (
								<Select
									label="學制科目"
									options={eduSubjectOptions}
									value={stateFormParams.subject?.value || ""}
									message={stateFormParams.subject?.message || ""}
									onChange={(value) => onFormSelectChangeHandle(value, "subject")}
								/>
							)}
							<Select
								label="適用年度"
								options={yearMap}
								value={stateFormParams.year?.value || ""}
								message={stateFormParams.year?.message || ""}
								onChange={(value) => onFormSelectChangeHandle(value, "year")}
							/>
							<Select
								label="課綱"
								options={curriculumMap}
								value={stateFormParams.curriculum?.value || ""}
								message={stateFormParams.curriculum?.message || ""}
								onChange={(value) => onFormSelectChangeHandle(value, "curriculum")}
							/>
							{levelRadio !== SPECIAL_SUBJECT && (
								<Select
									label="版本"
									options={versionMap}
									value={stateFormParams.version?.value || ""}
									message={stateFormParams.version?.message || ""}
									onChange={(value) => onFormSelectChangeHandle(value, "version")}
								/>
							)}
							<div>匯入知識向度</div>
							<Upload
								fileList={stateFormParams.fileList?.value || []}
								onChange={changeFileList}
								prompt={
									<>
										僅限上傳一個 Microsoft Excel 檔<br />
										(副檔名 : .xls/.xlsx)
									</>
								}
								acceptFileType=".xls,.xlsx"
								message={isSubmitted && isKnowledgeFileEmpty ? "請上傳檔案" : ""}
							/>
						</div>
					</Tabs.TabPane>
					<Tabs.TabPane tab="大考冊次" key={TABS.book}>
						<div className="modalContent">
							{eduSubject && (
								<Select
									label="學制科目"
									options={eduSubjectOptions}
									value={stateFormParams.subject?.value || ""}
									message={stateFormParams.subject?.message || ""}
									onChange={(value) => onFormSelectChangeHandle(value, "subject")}
								/>
							)}
							<Select
								label="適用年度"
								options={yearMap}
								value={stateFormParams.year?.value || ""}
								message={stateFormParams.year?.message || ""}
								onChange={(value) => onFormSelectChangeHandle(value, "year")}
							/>
							{levelRadio !== NO_SYLLABUS && (
								<Select
									label="課綱"
									options={curriculumMap}
									value={stateFormParams.curriculum?.value || ""}
									message={stateFormParams.curriculum?.message || ""}
									onChange={(value) => onFormSelectChangeHandle(value, "curriculum")}
								/>
							)}
							<Select
								label="版本"
								options={versionMap}
								value={stateFormParams.version?.value || ""}
								message={stateFormParams.version?.message || ""}
								onChange={(value) => onFormSelectChangeHandle(value, "version")}
							/>
							{levelRadio === EXAM_CHAPTER && (
								<Select
									label="冊次"
									options={sortBookMap}
									value={stateFormParams.book?.value || ""}
									message={stateFormParams.book?.message || ""}
									onChange={(value) => onFormSelectChangeHandle(value, "book")}
								/>
							)}
							<div>匯入知識向度</div>
							<Upload
								fileList={stateFormParams.fileList?.value || []}
								onChange={changeFileList}
								prompt={
									<>
										僅限上傳一個 Microsoft Excel 檔<br />
										(副檔名 : .xls/.xlsx)
									</>
								}
								acceptFileType=".xls,.xlsx"
								message={isSubmitted && isKnowledgeFileEmpty ? "請上傳檔案" : ""}
							/>
							<div>匯入大考章節</div>
							<Upload
								fileList={stateFormParams.bigTest?.value || []}
								onChange={(value) => onFormSelectChangeHandle(value, "bigTest")}
								prompt={
									<>
										僅限上傳一個 Microsoft Excel 檔<br />
										(副檔名 : .xls/.xlsx)
									</>
								}
								acceptFileType=".xls,.xlsx"
								message={isSubmitted && isBigTestFileEmpty ? "請上傳檔案" : ""}
							/>
						</div>
					</Tabs.TabPane>
					<Tabs.TabPane tab="主題式無課綱" key={TABS.topic}>
						<div className="modalContent">
							{eduSubject && (
								<Select
									label="學制科目"
									options={eduSubjectOptions}
									value={stateFormParams.subject?.value || ""}
									message={stateFormParams.subject?.message || ""}
									onChange={(value) => onFormSelectChangeHandle(value, "subject")}
								/>
							)}
							<Select
								label="適用年度"
								options={yearMap}
								value={stateFormParams.year?.value || ""}
								message={stateFormParams.year?.message || ""}
								onChange={(value) => onFormSelectChangeHandle(value, "year")}
							/>
							<Select
								label="版本"
								options={versionMap}
								value={stateFormParams.version?.value || ""}
								message={stateFormParams.version?.message || ""}
								onChange={(value) => onFormSelectChangeHandle(value, "version")}
							/>
							<div>匯入知識向度</div>
							<Upload
								fileList={stateFormParams.fileList?.value || []}
								onChange={changeFileList}
								prompt={
									<>
										僅限上傳一個 Microsoft Excel 檔<br />
										(副檔名 : .xls/.xlsx)
									</>
								}
								acceptFileType=".xls,.xlsx"
								message={isSubmitted && isKnowledgeFileEmpty ? "請上傳檔案" : ""}
							/>
						</div>
					</Tabs.TabPane>
				</Tabs>
			</Modal>
		</UiChapterPage>
	);
};
